import React, { useState, useRef, useEffect } from "react";
import { 
  Search, 
  X, 
  Scissors, 
  Store, 
  MapPin, 
  Calendar, 
  Filter 
} from "lucide-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { toast } from "react-toastify";
import skipp from "../../assets/img/skipp-logo1.jpg";

const AppHeader = ({showminheadermob}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const searchRef = useRef(null);

  const data = useSelector((state) => state?.data) || [];
  const navigate = useNavigate();

  // Search states
  const [person, setPerson] = useState("");
  const [location, setLocation] = useState("");
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [nomEtablissement, setNomEtablissement] = useState("");

  const villes = [...new Set(data?.salons?.map((salon) => salon?.city?.toLowerCase()))] || [];

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchOpen(false);
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = (tab) => {
    if (tab === 1) {
      navigate('/sidelisting-page', {
        state: {
          service: person,
          city: location,
          date: checkOutDate,
        }
      });
    } else {
      if (nomEtablissement === "") {
        toast.error("Veuillez entrer un nom d'établissement !");
      } else {
        navigate('/sidelisting-page', {
          state: {
            searchInput: nomEtablissement
          }
        });
      }
    }
    setIsSearchOpen(false);
  };

  const formattedDate = checkOutDate
    ? format(checkOutDate, "PP", { locale: fr })
    : "Choisir une date";

  return (
    <div>
      {showminheadermob ?
      <header className="fixed top-[-2px] left-0 right-0 z-50 bg-white shadow-sm flex items-center justify-center px-4 h-20">
        <div className="flex items-center space-x-2">
          <img
            src={skipp}
            alt="Skipp Logo"
            className="w-20 h-20 object-contain cursor-pointer"
          />
        </div>
      </header>
      :
      <header className="fixed top-[-2px] left-0 right-0 z-50 bg-white shadow-sm flex items-center justify-between px-4 h-20">
        <div className="flex items-center space-x-2">
          <img
            src={skipp}
            alt="Skipp Logo"
            className="w-20 h-20 object-contain cursor-pointer"
          />
        </div>
        <div>
          <button 
            className="text-gray-600 hover:text-[#fd8000] transition-colors"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >
            <Search size={24} />
          </button>
        </div>
      </header>
      }

      {isSearchOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-[100] bg-white overflow-y-auto animate-fade-in">
          <div ref={searchRef} className="p-4 space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <Filter className="mr-2 text-[#fd8000]" /> 
                Recherche
              </h2>
              <button 
                onClick={() => setIsSearchOpen(false)}
                className="text-gray-600 hover:text-[#fd8000] transition-colors"
              >
                <X size={24} />
              </button>
            </div>

            <div className="mb-4 flex border rounded-full overflow-hidden shadow-sm">
              <button
                onClick={() => setActiveTab(0)}
                className={`flex-1 py-2 text-center transition-colors flex items-center justify-center ${
                  activeTab === 0 
                    ? 'bg-[#fd8000] text-white' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Scissors className="mr-2" /> Prestation
              </button>
              <button
                onClick={() => setActiveTab(1)}
                className={`flex-1 py-2 text-center transition-colors flex items-center justify-center ${
                  activeTab === 1 
                    ? 'bg-[#fd8000] text-white' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Store className="mr-2" /> Etablissement
              </button>
            </div>

            {activeTab === 0 && (
              <div className="space-y-4">
                <div className="relative">
                  <select 
                    value={person?.name || ""} 
                    onChange={(e) => {
                      const selectedService = data?.services.find(s => s.name === e.target.value);
                      setPerson(selectedService || "");
                    }}
                    className="w-full p-3 pl-10 border rounded-lg appearance-none focus:ring-2 focus:ring-[#fd8000] focus:border-transparent"
                  >
                    <option className="text-xs" value="">Toutes les prestations</option>
                    {data?.services?.map((service, index) => (
                      <option className="text-xs" key={index} value={service.name}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <div className="relative">
                  <select 
                    value={location} 
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full p-3 pl-10 border rounded-lg appearance-none focus:ring-2 focus:ring-[#fd8000] focus:border-transparent"
                  >
                    <option className="text-xs" value="">Toutes les villes</option>
                    {villes.map((ville, index) => (
                      <option className="text-xs capitalize" key={index} value={ville}>
                        {ville}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <div className="relative">
                  <div 
                    onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                    className="w-full p-3 pl-10 border rounded-lg flex items-center cursor-pointer"
                  >
                    {formattedDate}
                  </div>
                  {isDatePickerOpen && (
                    <div className="mt-2">
                      <DayPicker
                        mode="single"
                        selected={checkOutDate}
                        onSelect={(date) => {
                          setCheckOutDate(date);
                          setIsDatePickerOpen(false);
                        }}
                        fromDate={new Date()}
                        locale={fr}
                        className="w-full"
                      />
                    </div>
                  )}
                </div>

                <button
                  onClick={() => handleSearch(1)}
                  className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center"
                >
                  <Search className="mr-2" /> Rechercher
                </button>
              </div>
            )}

            {activeTab === 1 && (
              <div className="space-y-4">
                <div className="relative">
                  <input 
                    type="text"
                    placeholder="Nom de l'établissement"
                    value={nomEtablissement}
                    onChange={(e) => setNomEtablissement(e.target.value)}
                    className="w-full p-3 pl-10 border rounded-lg focus:ring-2 focus:ring-[#fd8000] focus:border-transparent"
                  />
                </div>
                <button
                  onClick={() => handleSearch(2)}
                  className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center"
                >
                  <Search className="mr-2" /> Rechercher
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppHeader;