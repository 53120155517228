import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import Zoom from "react-medium-image-zoom";
import { MapPin, Star, Clock, BadgeCheck, ChevronRight } from "lucide-react";
import PlaceIcon from "../../assets/images/geoplace.png";
import HairCutIcon from "@mui/icons-material/ContentCut";
import HairColorIcon from "@mui/icons-material/Brush";
import FacialIcon from "@mui/icons-material/Face";
import PedicureIcon from "@mui/icons-material/Spa";
import MakeupIcon from "@mui/icons-material/FaceRetouchingNatural";
import MassageIcon from "@mui/icons-material/SelfImprovement";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import "./style.scss"

import { BASE_URL_IMAGE } from "../../constants/actions";
import {
  EmployeeRatings,
  ReviewFilter,
  ReviewFilterTwo,
  ReviewList,
  ServicesTabsComp,
} from "../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "leaflet/dist/leaflet.css";
import "react-medium-image-zoom/dist/styles.css";

const icon = L.icon({
  iconUrl: PlaceIcon,
  iconSize: [35, 35],
});

const iconMap = {
  HairCutIcon: HairCutIcon,
  HairColorIcon: HairColorIcon,
  FacialIcon: FacialIcon,
  PedicureIcon: PedicureIcon,
  MakeupIcon: MakeupIcon,
  MassageIcon: MassageIcon,
  StarBorderIcon: StarBorderIcon,
};

function DetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state?.id;
  const salons = useSelector((state) => state?.data?.salons);
  const [salon, setSalon] = useState();
  const reservation = useSelector((state) => state?.reservation);
  const [totalPrice, setTotalPrice] = useState(0);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [selectedService, setSelectedService] = useState("All");
  const [selectedRating, setSelectedRating] = useState("All");

  useEffect(() => {
    if (isNaN(id) || !location?.state?.id) {
      navigate(location?.prevPath || "/");
      return;
    }
    const sl = salons?.find((sal) => sal?.id === parseInt(id));
    if (salons?.length && !sl) {
      navigate(location?.prevPath || "/");
      return;
    }
    setSalon(sl);
  }, [id, salons]);

  useEffect(() => {
    if (reservation?.services?.length) {
      const total = reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );

      setTotalPrice(total.toFixed(2));
    }
  }, [reservation]);

  const handleFilter = () => {
    let filtered = salon?.user?.reviews || [];

    if (selectedService !== "All") {
      filtered = filtered?.filter((review) => {
        const employee = salon?.employees?.find(
          (emp) => emp?.id === review?.employee_id
        );
        return employee?.services?.some(
          (s) => s.id === parseInt(selectedService)
        );
      });
    }

    if (selectedRating !== "All") {
      filtered = filtered?.filter(
        (review) => review?.rating === parseInt(selectedRating)
      );
    }

    setFilteredReviews(filtered);
  };

  useEffect(() => {
    handleFilter();
  }, [selectedService, selectedRating, salon]);

  const getStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = 5;

    return {
      fullStars,
      hasHalfStar,
      emptyStars: totalStars - fullStars - (hasHalfStar ? 1 : 0),
    };
  };

  const avgRating = parseFloat(salon?.average_rating) || 0;
  const { fullStars, hasHalfStar, emptyStars } = getStars(avgRating);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white shadow-sm">
        <div className="container mx-auto px-4 py-6">
          <div className="space-y-4">
            <h1 className="text-3xl font-bold text-gray-900">{salon?.name}</h1>

            <div className="flex flex-wrap gap-6 text-gray-600">
              <div className="flex items-center gap-2">
                <MapPin className="w-5 h-5 text-orange-500" />
                <span>
                  {salon?.address}, {salon?.city}
                </span>
              </div>

              <div className="flex items-center gap-1">
                {[...Array(fullStars)].map((_, index) => (
                  <Star
                    key={`full-${index}`}
                    className="w-5 h-5 fill-yellow-400 text-yellow-400"
                  />
                ))}
                {hasHalfStar && <Star className="w-5 h-5 text-yellow-400" />}
                {[...Array(emptyStars)].map((_, index) => (
                  <Star
                    key={`empty-${index}`}
                    className="w-5 h-5 text-gray-300"
                  />
                ))}
                <span className="ml-2">({salon?.user?.reviews?.length})</span>
              </div>

              <div className="flex items-center gap-2">
                <Clock className="w-5 h-5 text-green-500" />
                <span>
                  Ouvert Demain: {salon?.startTime} - {salon?.endTime}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Image Slider */}
        <div className="mt-6 bg-white py-8">
          <div className="container mx-auto px-4">
            <Slider {...settings}>
              {salon?.album?.map((item, index) => (
                <div key={index} className="px-2">
                  <Zoom>
                    <img
                      src={BASE_URL_IMAGE + item?.image}
                      alt={`Slide ${index + 1}`}
                      className="rounded-lg w-full h-64 object-cover cursor-pointer"
                    />
                  </Zoom>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* Services Quick View */}
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-wrap gap-4">
            {salon?.services?.slice(0, 5)?.map((item, index) => {
              const IconComponent = iconMap[item?.icon];
              return (
                <div
                  key={index}
                  className="flex items-center gap-2 bg-orange-50 rounded-full px-4 py-2"
                >
                  {IconComponent && (
                    <IconComponent className="w-5 h-5 text-orange-500" />
                  )}
                  <span className="font-medium">{item.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        {/* Description Section */}
        <section className="bg-white rounded-xl p-6 shadow-sm mb-8">
          <h2 className="text-2xl font-bold text-black mb-4">Description</h2>
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: salon?.description }}
          />
        </section>

        {/* Services Section */}
        <section className="bg-white rounded-xl p-6 shadow-sm mb-8">
          <h2 className="text-2xl text-black font-bold mb-4">Services</h2>
          <ServicesTabsComp services={salon?.services} salon={salon} />
        </section>

        {/* Reviews Section */}
        <section className="bg-white rounded-xl p-4 shadow-sm mb-8">
          <div className="flex items-center justify-between mb-6 flex-wrap gap-4">
            <h2 className="text-2xl text-black font-bold w-full md:w-auto">
              Avis sur l'établissement
            </h2>
            <div className="flex items-center gap-2 flex-wrap">
              <span className="text-3xl font-bold text-orange-600">
                {salon?.average_rating || "0,0"}
              </span>
              <div className="flex flex-col">
                {/* <div className="flex gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-4 h-4 text-yellow-400" />
                  ))}
                </div> */}
                <div className="flex items-center gap-1">
                {[...Array(fullStars)].map((_, index) => (
                  <Star
                    key={`full-${index}`}
                    className="w-5 h-5 fill-yellow-400 text-yellow-400"
                  />
                ))}
                {hasHalfStar && <Star className="w-5 h-5 text-yellow-400" />}
                {[...Array(emptyStars)].map((_, index) => (
                  <Star
                    key={`empty-${index}`}
                    className="w-5 h-5 text-gray-300"
                  />
                ))}
              </div>
                <span className="text-sm text-gray-600">
                  {salon?.user?.reviews?.length} avis
                </span>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="space-y-2">
              <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                <h3 className="text-xl text-black font-bold">
                  Filtrer par type de prestation
                </h3>
                <ReviewFilter
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                  services={salon?.services}
                />

                <h3 className="text-xl text-black font-bold">
                  Filtrer par évaluation
                </h3>
                <ReviewFilterTwo
                  selectedRating={selectedRating}
                  setSelectedRating={setSelectedRating}
                />
              </div>

              <div className="flex flex-col items-start gap-2 bg-green-50 rounded-lg p-4">
                <div className="flex gap-3 items-center">
                  <BadgeCheck className="w-7 h-7 text-green-500 flex-shrink-0" />
                  <h3 className="text-2xl font-bold text-black">
                    Avis vérifiés
                  </h3>
                </div>
                <div>
                  <p className="text-sm text-gray-600 mt-1">
                    Rédigé par nos clients, pour vous faire une idée de la
                    qualité du salon.
                  </p>
                </div>
              </div>
            </div>

            <div className="md:col-span-2">
              <ReviewList reviews={filteredReviews} />
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section className="bg-white rounded-xl p-6 shadow-sm mb-8">
          <h2 className="text-2xl font-bold text-black mb-6">
            Rencontrez l'équipe
          </h2>
          <EmployeeRatings salon={salon} />
        </section>

        {/* Map Section */}
        <section className="bg-white rounded-xl p-6 shadow-sm mb-32">
          <h2 className="text-2xl font-bold text-black mb-6">
            Notre localisation
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start gap-3">
              <MapPin className="mt-1 w-6 h-6 text-orange-500 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-orange-800">{salon?.name}</h3>
                <p className="text-gray-600 mt-1">
                  {salon?.address}, {salon?.city}
                </p>
              </div>
            </div>

            <div className="h-96 rounded-lg overflow-hidden">
              <MapContainer
                className="h-full w-full"
                center={[
                  parseFloat(salon?.latitude) || 31.6225212,
                  parseFloat(salon?.longitude) || -7.245345,
                ]}
                zoom={13}
                zoomControl={false}
                dragging={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                attributionControl={false}
              >
                <TileLayer url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}@2x.png?key=Sgn4Dy6BRJC7dV5GrIKS" />
                <Marker
                  position={[
                    parseFloat(salon?.latitude) || 31.6225212,
                    parseFloat(salon?.longitude) || -7.9898301,
                  ]}
                  icon={icon}
                >
                  <Popup>votre position!</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </section>
      </div>
      {/* Floating Reservation Bar */}
      <div className="fixed z-10000 bottom-0 left-0 right-0 ">
      {(reservation?.services?.length &&
        reservation?.salon?.id === parseInt(id)) ? (
          <div className="bg-white shadow-lg border-t">
            {/* fixed z-100000  */}
            <div className="container mx-auto px-4 py-4 flex items-center justify-between flex-col md:flex-row">
              <div className="mb-4 md:mb-0">
                <div className="flex items-center gap-2">
                  <span className="font-bold text-xl text-gray-800">
                    {reservation?.services?.length || 0} prestation
                  </span>
                  <span className="font-bold text-lg text-orange-600">
                    {totalPrice} Dhs
                  </span>
                </div>
                <p className="text-sm text-gray-800">
                  Vous pouvez ajouter d'autres prestations ou continuer
                </p>
              </div>

              <button
                onClick={() => navigate("/availability", { state: { id: id } })}
                className="bg-orange-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-orange-700 transition-colors flex items-center gap-2 w-full md:w-auto"
              >
                Je choisis mon horaire
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        ) : "" }
      </div>
    </div>
  );
}

export default DetailPage;
