import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  Youtube, 
  ArrowRight
} from "lucide-react";
import { Skipp1 } from "../../assets/images";

function Footer() {
  const navigate = useNavigate();
  const footer = useSelector(state => state?.data?.footer);
  const [activeSection, setActiveSection] = useState(null);

  const footerSections = [
    {
      title: "Explorer",
      links: [
        { to: "/", label: "Accueil" },
        { to: "/info-apropos", label: "À propos" },
        { to: "/sidelisting-page", label: "Liste des salons" },
        { to: "/blog", label: "Blog" }
      ]
    },
    {
      title: "Légal",
      links: [
        { to: "/conditions", label: "Conditions générales" },
        { to: "/politique", label: "Politique de confidentialité" },
        { to: "/rgbd", label: "Droits et protection" },
        { to: "/contact", label: "Contactez-nous" }
      ]
    }
  ];

  const socialLinks = [
    { 
      to: "https://facebook.com/skipp", 
      icon: Facebook,
      ariaLabel: "Facebook" 
    },
    { 
      to: "https://twitter.com/skipp", 
      icon: Twitter,
      ariaLabel: "Twitter" 
    },
    { 
      to: "https://instagram.com/skipp", 
      icon: Instagram,
      ariaLabel: "Instagram" 
    },
    { 
      to: "https://youtube.com/skipp", 
      icon: Youtube,
      ariaLabel: "YouTube" 
    }
  ];

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto px-4 py-16 max-w-screen-xl">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Brand & Contact Column */}
          <div className="space-y-6">
            <div className="flex items-center space-x-4 mb-2">
              <img 
                src={Skipp1} 
                alt="Skipp Logo" 
                className="w-24 h-24 object-contain cursor-pointer" 
                onClick={() => navigate("/")}
              />
              {/* <span className="text-xl font-bold text-white">Skipp</span> */}
            </div>
            
            {footer?.descriptionLogo && (
              <p className="text-gray-300 text-sm mb-6">
                {footer.descriptionLogo}
              </p>
            )}

            {/* Contact Info */}
            <div className="space-y-6">
              {footer?.phone && (
                <div className="flex items-center space-x-3">
                  <div className="bg-blue-400 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs text-gray-400 m-0">{footer.titlePhone || "Téléphone"}</p>
                    <a
                      href={`tel:${footer.phone.replace(/\s/g, '')}`}
                      className="text-sm text-white hover:text-blue-300 transition"
                    >
                      {footer.phone}
                    </a>
                  </div>
                </div>
              )}
              
              {footer?.email && (
                <div className="flex items-center space-x-3">
                  <div className="bg-green-400 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                      <polyline points="22,6 12,13 2,6"/>
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs text-gray-400 m-0">{footer.titleEmail || "Email"}</p>
                    <a
                      href={`mailto:${footer.email}`}
                      className="text-sm text-white hover:text-green-300 transition"
                    >
                      {footer.email}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Navigation Sections - Desktop */}
          <div className="hidden md:grid grid-cols-2 gap-8">
            {footerSections.map((section, index) => (
              <div key={index}>
                <h4 className="text-lg text-center font-semibold mb-5 text-white border-b border-gray-800 pb-2">
                  {section.title}
                </h4>
                <ul className="space-y-3 pr-14 flex flex-col items-center">
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <Link
                        to={link.to}
                        className="group flex items-center text-gray-300 hover:text-white text-sm transition-all duration-300"
                      >
                        <ArrowRight 
                          size={16} 
                          className="mr-2 text-gray-500 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all duration-300" 
                        />
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Mobile Accordion */}
          <div className="md:hidden space-y-4">
            {footerSections.map((section, index) => (
              <div 
                key={index} 
                className="border-b border-gray-800 pb-4"
              >
                <button
                  onClick={() => toggleSection(index)}
                  className="w-full flex justify-between items-center"
                  aria-expanded={activeSection === index}
                >
                  <h4 className="font-semibold text-white">{section.title}</h4>
                  <span className={`text-gray-400 transform transition ${
                    activeSection === index ? 'rotate-90' : ''
                  }`}>
                    ›
                  </span>
                </button>
                {activeSection === index && (
                  <ul className="space-y-3 mt-4">
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <Link
                          to={link.to}
                          className="text-gray-300 hover:text-white w-full text-left text-sm flex items-center"
                        >
                          <ArrowRight 
                            size={16} 
                            className="mr-2 text-gray-500" 
                          />
                          {link.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>

          
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-800 pt-8 mt-8 flex flex-col md:flex-row justify-between items-center">
          {/* Social Links */}
          <div className="flex space-x-4 mb-4 md:mb-0">
            {socialLinks.map((link, index) => (
              <a 
                key={index} 
                href={link.to} 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label={link.ariaLabel}
                className="text-gray-400 hover:text-white transition"
              >
                <link.icon size={20} />
              </a>
            ))}
          </div>
          
          {/* Copyright */}
          <p className="text-sm text-gray-400 text-center md:text-left">
            © 2024 <Link to="/" className="hover:text-white mx-1">Skipp</Link>. 
            Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;