import React, { useState } from 'react';
import { 
  Scissors, 
  Palette, 
  HandMetal, 
  Footprints,
  Eye, 
  Razor, 
  Building, 
  Briefcase, 
  ChevronRight, 
  ChevronLeft,
  Check,
  ScissorsIcon,
  Store
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Skippblue } from '../../assets/images';
import { CleanHandsOutlined } from '@mui/icons-material';

const PartnerSignup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    businessName: '',
    address: '',
    postalCode: '',
    city: '',
    services: [],
    ownerFirstName: '',
    ownerLastName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerBirthdate: '',
    password: '',
    confirmPassword: '',
    businessType: '',
    termsAccepted: false
  });

  const services = [
    { id: 1, name: 'Coiffure', icon: <ScissorsIcon className="w-10 h-10" /> },
    { id: 2, name: 'Maquillage', icon: <Palette className="w-10 h-10" /> },
    // { id: 3, name: 'Manucure', icon: <HandMetal className="w-10 h-10" /> },
    { id: 4, name: 'Pédicure', icon: <Footprints className="w-10 h-10" /> },
    { id: 5, name: 'Massage', icon: <CleanHandsOutlined style={{fontSize: "2.4rem"}} /> },
    { id: 6, name: 'Extensions de Cils', icon: <Eye className="w-10 h-10" /> },
    // { id: 7, name: 'Barbier', icon: <Razor className="w-10 h-10" /> },
    { id: 8, name: 'Soins Capillaires', icon: <Scissors className="w-10 h-10" /> }
  ];

  const nextStep = () => {
    setCurrentStep(Math.min(currentStep + 1, 5));
  };

  const prevStep = () => {
    setCurrentStep(Math.max(currentStep - 1, 1));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const toggleService = (serviceId) => {
    setFormData(prev => ({
      ...prev,
      services: prev.services.includes(serviceId)
        ? prev.services.filter(id => id !== serviceId)
        : [...prev.services, serviceId]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add submit logic here
  };

  const navigate = useNavigate();

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <div className="text-center space-y-6 p-8">
            <div className="flex justify-center items-center cursor-pointer" onClick={() => navigate('/entreprise')}>
              <img
                src={Skippblue}
                alt="Skipp Logo"
                className="h-8 sm:h-10 mr-2"
              />
              <span className="text-lg sm:text-2xl font-bold text-[#2548FF]">
                Business
              </span>
            </div>
            <h1 className="text-3xl font-bold text-gray-800">
              Bienvenue chez Skipp Partenaire
            </h1>
            <p className="text-gray-600 text-lg">
              Rejoignez notre réseau et développez votre entreprise avec Skipp
            </p>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6 p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Informations sur votre établissement
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <input
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                placeholder="Nom de l'Institut"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Adresse"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder="Code Postal"
                type="text"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
              />
              <input
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Ville"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6 p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Sélectionnez vos services
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {services.map(service => (
                <button
                  key={service.id}
                  onClick={() => toggleService(service.id)}
                  className={`flex flex-col items-center p-4 rounded-lg border-2 transition-all ${
                    formData.services.includes(service.id) 
                    ? 'bg-[#2548FF] text-white border-[#2548FF]' 
                    : 'border-gray-200 hover:bg-gray-50'
                  }`}
                >
                  {React.cloneElement(service.icon, {
                    className: `w-10 h-10 mb-2 ${
                      formData.services.includes(service.id) 
                      ? 'text-white' 
                      : 'text-[#2548FF]'
                    }`
                  })}
                  <span className="text-sm mt-2">{service.name}</span>
                </button>
              ))}
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-6 p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Informations personnelles
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <input
                name="ownerFirstName"
                value={formData.ownerFirstName}
                onChange={handleChange}
                placeholder="Prénom *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerLastName"
                value={formData.ownerLastName}
                onChange={handleChange}
                placeholder="Nom *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerEmail"
                type="email"
                value={formData.ownerEmail}
                onChange={handleChange}
                placeholder="Adresse e-mail *"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                required
              />
              <input
                name="ownerPhone"
                type="tel"
                value={formData.ownerPhone}
                onChange={handleChange}
                placeholder="Numéro de téléphone"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
              />
              
              <div className="md:col-span-2 grid md:grid-cols-2 gap-4">
                <input
                  name="ownerBirthdate"
                  type="date"
                  value={formData.ownerBirthdate}
                  onChange={handleChange}
                  placeholder="Date de Naissance"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                />
                <input
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Mot de passe *"
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2548FF]"
                  required
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="space-y-6 p-8">
            <h2 className="text-2xl font-bold text-gray-800">
              Type d'établissement
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <button
                onClick={() => setFormData(prev => ({...prev, businessType: 'salon'}))}
                className={`flex items-center justify-center gap-4 p-6 border-2 rounded-lg transition-all ${
                  formData.businessType === 'salon' 
                  ? 'bg-[#2548FF] text-white border-[#2548FF]' 
                  : 'border-gray-200 hover:bg-gray-50'
                }`}
              >
                <Store className={`w-8 h-8 ${
                  formData.businessType === 'salon' 
                  ? 'text-white' 
                  : 'text-[#2548FF]'
                }`} />
                <span className="text-lg">Salon</span>
              </button>
              <button
                onClick={() => setFormData(prev => ({...prev, businessType: 'home'}))}
                className={`flex items-center justify-center gap-4 p-6 border-2 rounded-lg transition-all ${
                  formData.businessType === 'home' 
                  ? 'bg-[#2548FF] text-white border-[#2548FF]' 
                  : 'border-gray-200 hover:bg-gray-50'
                }`}
              >
                <Briefcase className={`w-8 h-8 ${
                  formData.businessType === 'home' 
                  ? 'text-white' 
                  : 'text-[#2548FF]'
                }`} />
                <span className="text-lg">À domicile</span>
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.termsAccepted}
                  onChange={() => setFormData(prev => ({...prev, termsAccepted: !prev.termsAccepted}))}
                  className="mr-2 accent-[#2548FF]"
                />
                <label className="text-sm">
                  J'accepte les{' '}
                  <a href="/conditions" className="text-[#2548FF] underline">
                    conditions générales
                  </a>{' '}
                  et la{' '}
                  <a href="/politique" className="text-[#2548FF] underline">
                    politique de confidentialité
                  </a>
                </label>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex flex-col">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-20 bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
          <div className="flex items-center cursor-pointer" onClick={() => navigate('/entreprise')}>
            <img
              src={Skippblue}
              alt="Skipp Logo"
              className="h-8 sm:h-10 mr-2"
            />
            <span className="text-lg sm:text-2xl font-bold text-[#2548FF]">
              Business
            </span>
          </div>
        </div>
      </header>

      <div className="fixed top-14 left-0 right-0 z-20">
        <div className="w-full mx-auto py-2">
          <div className="w-full flex">
            {[1, 2, 3, 4, 5].map(step => (
              <div
                key={step}
                className={`flex-1 h-1.5 transition-all ${
                  currentStep >= step ? 'bg-[#2548FF]' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex-grow flex items-center justify-center pt-32 pb-16">
        <div className="w-full max-w-5xl bg-white shadow-2xl rounded-3xl overflow-hidden relative">
          {/* Step Indicator */}
          <div className="absolute top-6 left-6 text-gray-500 text-sm">
            Étape {currentStep}
          </div>

          <div className="p-4 sm:p-12">
            <form onSubmit={handleSubmit}>
              {renderStep()}
            </form>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-3 bg-white/80 backdrop-blur-sm">
          <div className="w-full mx-auto flex justify-between">
            {currentStep > 1 && (
              <button 
                type="button"
                onClick={prevStep} 
                className="flex items-center gap-2 text-[#2548FF] hover:bg-blue-50 px-6 py-2 rounded-full transition"
              >
                <ChevronLeft className="w-5 h-5" /> Précédent
              </button>
            )}
            {currentStep < 5 && (
              <button 
                type="button"
                onClick={nextStep} 
                className="ml-auto flex items-center gap-2 bg-[#2548FF] text-white px-6 py-2 rounded-full hover:bg-blue-700 transition"
              >
                Suivant <ChevronRight className="w-5 h-5" />
              </button>
            )}
            {currentStep === 5 && (
              <button 
                type="submit"
                disabled={!formData.termsAccepted || !formData.businessType}
                className="ml-auto flex items-center gap-2 bg-[#2548FF] text-white px-6 py-2 rounded-full hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Check className="w-5 h-5" /> Finaliser l'inscription
              </button>
            )}
          </div>
        </div>
      </div> 
    </div>
  );
};

export default PartnerSignup;