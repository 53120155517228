import React, { useEffect, useMemo, useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Clock,
  XCircle,
  ClipboardCheck,
  MapPin,
  Calendar,
  CreditCard,
  Star,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import GetBookingStatus from "../../helpers/getBookingStatus";
import { apiClient } from "../../actions/api";
import toast from "react-hot-toast";
import { BOOKINGS, SALONS } from "../../constants/actions";
import { FetchData } from "../../actions/data";
import FormateDateRange from "../../helpers/FormateDateRange";

function DashMain() {
  const [expandedItems, setExpandedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const itemsPerPage = 5;
  const maxVisibleButtons = 5;

  const [statistics, setStatistics] = useState({
    attente: "00",
    annulee: "00",
    termine: "00",
  });

  const bookings = useSelector((state) => state?.data?.bookings);

  useEffect(() => {
    if (bookings?.length) {
      const stats = { attente: 0, annulee: 0, termine: 0 };

      bookings.forEach((booking) => {
        const { availability, status } = booking;
        const bookingStatus = GetBookingStatus(
          availability?.date,
          availability?.start_time,
          availability?.end_time,
          status
        );

        switch (bookingStatus.type) {
          case "En Attente":
            stats.attente += 1;
            break;
          case "Annulée":
            stats.annulee += 1;
            break;
          case "Terminé":
            stats.termine += 1;
            break;
          default:
            break;
        }
      });

      setStatistics({
        attente: stats.attente.toString().padStart(2, "0"),
        annulee: stats.annulee.toString().padStart(2, "0"),
        termine: stats.termine.toString().padStart(2, "0"),
      });
    }
  }, [bookings]);

  const toggleItem = (index) => {
    setExpandedItems((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index]
    );
  };

  // Filter bookings based on active filter
  const filteredBookings = useMemo(() => {
    if (!bookings) return [];

    return bookings.filter((booking) => {
      const bookingStatus = GetBookingStatus(
        booking?.availability?.date,
        booking?.availability?.start_time,
        booking?.availability?.end_time,
        booking?.status
      );

      if (activeFilter === null) return true;

      switch (activeFilter) {
        case "En Attente":
          return bookingStatus.type === "En Attente";
        case "Annulée":
          return bookingStatus.type === "Annulée";
        case "Terminé":
          return bookingStatus.type === "Terminé";
        default:
          return true;
      }
    });
  }, [bookings, activeFilter]);

  const totalPages = Math.ceil(filteredBookings?.length / itemsPerPage);

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [filteredBookings]);

  const currentItems = useMemo(() => {
    const sortedBookings = [...filteredBookings].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedBookings.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredBookings]);

  // Stat Card with filter functionality
  const StatCard = ({ icon, title, count, bgColor }) => (
    <div
    className={`${bgColor} p-3 rounded-xl shadow-sm flex flex-col items-center justify-center space-y-1 cursor-pointer 
      ${activeFilter === title ? 'border-1 border-orange-700 !bg-[#492602]' : ''}
      hover:opacity-80 transition-all`}
    onClick={() => setActiveFilter(activeFilter === title ? null : title)} // Toggle active filter
  >
    <div className={`${activeFilter === title ? "bg-orange-50" : "bg-white"} p-2 rounded-full`}>{icon}</div>
    <p className={`${activeFilter === title ? "text-white" : "text-gray-600" } text-xs text-center`}>{title}</p>
    <h3 className={`${activeFilter === title ? "text-white" : "text-gray-600" } text-lg font-bold`}>{count}</h3>
  </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen pt-10 pb-20">
      {/* Statistics Section */}
      <div className="px-4 mb-6">
        <div className="grid grid-cols-3 gap-3">
          <StatCard 
            icon={<Clock className="w-6 h-6 text-yellow-500" />} 
            title="En Attente" 
            count={statistics.attente} 
            bgColor="bg-yellow-50"
          />
          <StatCard
            icon={<XCircle className="w-6 h-6 text-red-500" />}
            title="Annulée"
            count={statistics.annulee}
            bgColor="bg-red-50"
          />
          <StatCard
            icon={<ClipboardCheck className="w-6 h-6 text-blue-500" />}
            title="Terminé"
            count={statistics.termine}
            bgColor="bg-blue-50"
          />
        </div>
      </div>

      {/* Reservations Section */}
      <div className="space-y-4 px-4">
        {currentItems.map((booking, index) => (
          <ReservationCard
            key={booking.id}
            booking={booking}
            isExpanded={expandedItems.includes(index)}
            onToggle={() => toggleItem(index)}
          />
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-top py-3 px-4">
          <div className="flex justify-between items-center space-x-2">
            <button
              className="bg-gray-200 px-4 py-2 rounded-lg disabled:opacity-50 flex items-center"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <ChevronLeft className="mr-2" /> Précédent
            </button>
            <div className="flex space-x-2">
              {visiblePages.map((page) => (
                <button
                  key={page}
                  className={`w-10 h-10 rounded-full ${
                    currentPage === page
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <button
              className="bg-gray-200 px-4 py-2 rounded-lg disabled:opacity-50 flex items-center"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Suivant <ChevronRight className="ml-2" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const ReservationCard = ({ booking, isExpanded, onToggle }) => {
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [review, setReview] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);
  const client = useSelector((state) => state?.auth?.client);

  const dispatch = useDispatch();

  const handleRatingClick = (selectedRating) => {
    setSelectedRating(selectedRating);
  };

  const handleReviewSubmit = async (item) => {
    try {
      await apiClient().post("/reviews", {
        user_id: item?.user_id,
        admin_id: item?.availability?.salon?.user_id,
        employee_id: item?.availability?.employee?.id,
        booking_id: item?.id,
        rating: selectedRating,
        comment: review,
      });
      toast.success(
        "Votre avis a été envoyé avec succès. Merci pour votre retour !"
      );
      setIsReviewOpen(false);
      setSelectedRating(null);
      setReview("");
      dispatch(FetchData('/completedSalons', SALONS));
      dispatch(
        FetchData(
          `/clientBookings/${client?.id}`,
          BOOKINGS
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const renderStatusBadge = (status) => {
    const statusStyles = {
      Acceptée: "bg-green-100 text-green-800",
      Annulée: "bg-red-100 text-red-800",
      Terminé: "bg-blue-100 text-blue-800",
    };

    return (
      <span
        className={`px-2 py-1 rounded-full text-xs font-medium ${
          statusStyles[status] || "bg-gray-100 text-gray-800"
        }`}
      >
        {status}
      </span>
    );
  };

  const status = booking?.availability?.date &&
    GetBookingStatus(
      booking?.availability?.date,
      booking?.availability?.start_time,
      booking?.availability?.end_time,
      booking?.status
    );

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={onToggle}
      >
        <div>
          <h3 className="font-bold text-lg text-gray-800">#{booking.id}</h3>
          <p className="text-sm text-gray-500">{booking.availability.salon.name}</p>
        </div>
        <div className="flex items-center space-x-2">
          {renderStatusBadge("Acceptée")}
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>

      {isExpanded && (
        <div className="p-4 border-t space-y-4">
          <div className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg">
            <MapPin className="text-gray-500 w-6 h-6" />
            <div>
              <p className="font-semibold text-gray-800">{booking.availability.salon.name}</p>
              <p className="text-sm text-gray-500">
                {booking.availability.salon.address},{" "}
                {booking.availability.salon.city}
              </p>
            </div>
          </div>
          
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Calendar className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700"> 
                {booking?.availability?.date &&
                  FormateDateRange(
                    booking?.availability?.date,
                    booking?.availability?.start_time,
                    booking?.availability?.end_time
                  )}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <Star className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700">
                {booking?.services
                  ?.map((service) => service?.name)
                  ?.join(", ")}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <CreditCard className="text-gray-500 w-5 h-5" />
              <span className="text-gray-700 font-semibold">
                {booking?.payment?.amount} Dhs
              </span>
            </div>
          </div>
          
          {(status?.type === "Terminé" && !booking?.reviews?.length > 0) ? (
            <div className="bg-gray-50 p-3 rounded-lg">
              <div 
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setIsReviewOpen(!isReviewOpen)}
              >
                <h5 className="text-lg font-semibold text-gray-800">
                  Laissez votre avis
                </h5>
                {isReviewOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              
              {isReviewOpen && (
                <div className="mt-4 space-y-3">
                  <textarea
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-200 transition-all"
                    rows="3"
                    placeholder="Votre commentaire..."
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  ></textarea>
                  
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          className={`text-2xl focus:outline-none transition-colors ${
                            star <= selectedRating ? "text-yellow-400" : "text-gray-300"
                          }`}
                          onClick={() => handleRatingClick(star)}
                        >
                          ★
                        </button>
                      ))}
                    </div>
                    
                    <button
                      onClick={() => handleReviewSubmit(booking)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                      disabled={!selectedRating}
                    >
                      Soumettre
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-gray-50 p-3 rounded-lg">
              <div 
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setIsReviewOpen(!isReviewOpen)}
              >
                <h5 className="text-lg font-semibold text-gray-800">
                  Avis ajouté
                </h5>
                {isReviewOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              
              {isReviewOpen && (
                <div className="mt-4 space-y-3">
                  <textarea
                    className="w-full p-3 border rounded-lg bg-white"
                    rows="3"
                    value={booking?.reviews[0]?.comment}
                    readOnly
                  ></textarea>
                  
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button
                        key={star}
                        className={`text-2xl focus:outline-none transition-colors ${
                          star <= booking?.reviews[0]?.rating ? "text-yellow-400" : "text-gray-300"
                        }`}
                      >
                        ★
                      </button>
                    ))}
                  </div>
                 </div> )}
            </div>
            )}
</div>
)}
</div>
  )

};

export default DashMain;