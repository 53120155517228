import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Mail, 
  Lock, 
  Eye, 
  EyeOff, 
  ArrowLeft, 
  CheckCircle, 
  AlertCircle 
} from 'lucide-react';
import { loginClient } from '../../actions/auth';
import { apiClient } from '../../actions/api';

function Login() {
  const [formData, setFormData] = useState({});
  const [resetStep, setResetStep] = useState('login');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [codeVerification, setCodeVerification] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginClient(formData)).then((response) => {
      if (response.success) {
        toast.success("Connexion réussie !", {
          icon: <CheckCircle className="text-green-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        navigate("/home");
      } else {
        toast.error("Échec de connexion: " + response.message, {
          icon: <AlertCircle className="text-red-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      }
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      apiClient().post("/forgot-password", { email: forgotPasswordEmail })
      .then(response => {
        if (response?.data?.success) {
          toast.success("Un e-mail de réinitialisation a été envoyé à votre adresse.", {
            icon: <CheckCircle className="text-green-500" />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          });
          setResetStep('enterCode');
        } else {
          toast.error("E-mail n'existe pas", {
            icon: <AlertCircle className="text-red-500" />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          });
        }
      });
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      console.error(error);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      apiClient().post("/check-code", { email: forgotPasswordEmail, resetNumber: codeVerification })
      .then(response => {
        if (response?.data?.status) {
          toast.success("Code vérifié avec succès !", {
            icon: <CheckCircle className="text-green-500" />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          });
          setResetStep('newPassword');
        } else {
          toast.error("Le code de réinitialisation est incorrect", {
            icon: <AlertCircle className="text-red-500" />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          });
          setResetStep('enterCode');
        }
      });
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      console.error(error);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPass) {
      toast.error("Les mots de passe ne correspondent pas !", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      return;
    }

    try {
      const response = await apiClient().post("/reset-password", {
        email: forgotPasswordEmail,
        password: formData.password
      });
      if (response.data.status) {
        toast.success("Mot de passe réinitialisé avec succès !", {
          icon: <CheckCircle className="text-green-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        setResetStep('login');
        navigate('/connexion');
      } else {
        toast.error("Échec de la réinitialisation du mot de passe : " + response.data.message, {
          icon: <AlertCircle className="text-red-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      console.error(error);
    }
  };

  const renderForm = () => {
    switch (resetStep) {
      case 'login':
        return (
          <form 
            onSubmit={handleSubmit} 
            className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-6 text-center text-[#fd8000]">
              Connexion
            </h2>
            
            <div className="mb-4 relative">
              <label 
                htmlFor="email" 
                className="block text-gray-700 mb-2 flex items-center"
              >
                <Mail className="mr-2 text-[#fd8000]" size={20} />
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  placeholder="Votre email"
                  id="email"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                  value={formData?.email || ""}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
                {/* <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /> */}
              </div>
            </div>

            <div className="mb-4 relative">
              <label 
                htmlFor="password" 
                className="block text-gray-700 mb-2 flex items-center"
              >
                <Lock className="mr-2 text-[#fd8000]" size={20} />
                Mot de passe
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Votre mot de passe"
                  id="password"
                  className="w-full p-3 pl-10 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                  value={formData?.password || ""}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  required
                />
                {/* <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /> */}
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 
                    <EyeOff className="text-gray-400" size={20} /> : 
                    <Eye className="text-gray-400" size={20} />
                  }
                </button>
              </div>
            </div>

            <div className="mb-4 text-right">
              <button 
                type="button"
                className="text-[#fd8000] hover:underline"
                onClick={() => setResetStep('forgotPassword')}
              >
                Mot de passe oublié ?
              </button>
            </div>

            <button
              type="submit"
              className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Connexion
            </button>
          </form>
        );

      case 'forgotPassword':
        return (
          <form 
            onSubmit={handleForgotPassword} 
            className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-md"
          >
            <div className="flex items-center mb-6">
              <button 
                type="button"
                onClick={() => setResetStep('login')}
                className="mr-4 hover:bg-gray-100 rounded-full p-2"
              >
                <ArrowLeft className="text-[#fd8000]" size={24} />
              </button>
              <h2 className="text-xl font-bold text-[#fd8000]">
                Réinitialisation du mot de passe
              </h2>
            </div>
            
            <div className="mb-4 relative">
              <label 
                htmlFor="forgotPasswordEmail" 
                className="block text-gray-700 mb-2 flex items-center"
              >
                <Mail className="mr-2 text-[#fd8000]" size={20} />
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  placeholder="Entrez votre email"
                  id="forgotPasswordEmail"
                  className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                />
                {/* <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /> */}
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Envoyer
            </button>
          </form>
        );

      case 'enterCode':
        return (
          <form 
            onSubmit={handleVerifyCode} 
            className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-md"
          >
            <h2 className="text-3xl font-bold mb-6 text-center text-[#fd8000]">
              Vérification
            </h2>
            
            <div className="mb-4">
              <label 
                htmlFor="code" 
                className="block text-gray-700 mb-2"
              >
                Code de réinitialisation
              </label>
              <input
                type="text"
                placeholder="Entrez le code"
                id="code"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                value={codeVerification}
                onChange={(e) => setCodeVerification(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Vérifier
            </button>
          </form>
        );

        case 'newPassword':
          return (
            <form 
              onSubmit={handleNewPasswordSubmit} 
              className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-md"
            >
              <h2 className="text-3xl font-bold mb-6 text-center text-[#fd8000]">
                Nouveau mot de passe
              </h2>
              
              <div className="mb-4 relative">
                <label 
                  htmlFor="password" 
                  className="block text-gray-700 mb-2 flex items-center"
                >
                  <Lock className="mr-2 text-[#fd8000]" size={20} />
                  Mot de passe
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Nouveau mot de passe"
                    id="password"
                    className="w-full p-3 pl-10 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                    value={formData?.password || ""}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    required
                  />
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 
                      <EyeOff className="text-gray-400" size={20} /> : 
                      <Eye className="text-gray-400" size={20} />
                    }
                  </button>
                </div>
              </div>
  
              <div className="mb-4 relative">
                <label 
                  htmlFor="confirmPassword" 
                  className="block text-gray-700 mb-2 flex items-center"
                >
                  <Lock className="mr-2 text-[#fd8000]" size={20} />
                  Confirmer mot de passe
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirmer mot de passe"
                    id="confirmPassword"
                    className="w-full p-3 pl-10 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
                    value={formData?.confirmPass || ""}
                    onChange={(e) => setFormData({ ...formData, confirmPass: e.target.value })}
                    required
                  />
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? 
                      <EyeOff className="text-gray-400" size={20} /> : 
                      <Eye className="text-gray-400" size={20} />
                    }
                  </button>
                </div>
              </div>
  
              <button
                type="submit"
                className="w-full bg-[#fd8000] text-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
              >
                Réinitialiser
              </button>
            </form>
          );
  
        default:
          return null;
      }
    };
  
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-8">
        <div className="absolute inset-0 bg-[#fd8000] opacity-10 -z-10"></div>
        
        <div className="w-full flex justify-center">
          {renderForm()}
        </div>
        
        <Toaster 
          position="top-right"
          toastOptions={{
            duration: 4000,
            success: {
              style: {
                background: 'white',
                color: 'black',
              },
            },
            error: {
              style: {
                background: 'white',
                color: 'black',
              },
            },
          }} 
        />
      </div>
    );
  }
  
  export default Login;