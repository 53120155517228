import React, { useEffect, useMemo, useState } from "react";
import img2 from "../../../assets/img/dashboard/mslogo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./style.scss";
import GetBookingStatus from "../../../helpers/getBookingStatus";
import { useDispatch, useSelector } from "react-redux";
import FormateDateRange from "../../../helpers/FormateDateRange";
import FormatDate2 from "../../../helpers/FormatDate2";
import { toast, Toaster } from "react-hot-toast";
import { apiClient } from "../../../actions/api";
import { FetchData } from "../../../actions/data";
import { BOOKINGS, SALONS } from "../../../constants/actions";

function DashMain() {
  const [expandedItems, setExpandedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const itemsPerPage = 5;
  const maxVisibleButtons = 5;
  const bookings = useSelector((state) => state?.data?.bookings);
  const [statistics, setStatistics] = useState({
    attente: "00",
    accepte: "00",
    annulee: "00",
    termine: "00",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (bookings?.length) {
      const stats = {
        attente: 0,
        accepte: 0,
        annulee: 0,
        termine: 0,
      };

      bookings.forEach((booking) => {
        const { availability, status } = booking;
        const bookingStatus = GetBookingStatus(
          availability?.date,
          availability?.start_time,
          availability?.end_time,
          status
        );
        if (status) {
          stats.accepte += 1;
        }
        switch (bookingStatus.type) {
          case "En Attente":
            stats.attente += 1;
            break;
          // case "Acceptée":
          //   stats.accepte += 1;
          //   break;
          case "Annulée":
            stats.annulee += 1;
            break;
          case "Terminé":
            stats.termine += 1;
            break;
          default:
            break;
        }
      });

      setStatistics({
        attente: stats.attente.toString().padStart(2, "0"),
        accepte: stats.accepte.toString().padStart(2, "0"),
        annulee: stats.annulee.toString().padStart(2, "0"),
        termine: stats.termine.toString().padStart(2, "0"),
      });
    }
  }, [bookings]);

  const toggleItem = (index) => {
    setExpandedItems(index === expandedItems ? null : index);
  };

  const totalPages = Math.ceil(bookings?.length / itemsPerPage);

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [bookings]);

  const currentItems = useMemo(() => {
    const sortedBookings = [...(bookings || [])].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return sortedBookings.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, bookings]);

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [review, setReview] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);

  const handleRatingClick = (selectedRating) => {
    setSelectedRating(selectedRating);
  };

  const client = useSelector((state) => state?.auth?.client);

  const handleReviewSubmit = async (item) => {
    try {
      await apiClient().post("/reviews", {
        user_id: item?.user_id,
        admin_id: item?.availability?.salon?.user_id,
        employee_id: item?.availability?.employee?.id,
        booking_id: item?.id,
        rating: selectedRating,
        comment: review,
      });
      toast.success(
        "Votre avis a été envoyé avec succès. Merci pour votre retour !"
      );
      setIsReviewOpen(false);
      setSelectedRating(null);
      setReview("");
      dispatch(FetchData('/completedSalons', SALONS));
      dispatch(
        FetchData(
          `/clientBookings/${client?.id}`,
          BOOKINGS
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  // const handleCancellReservation = (id) => {
  //   const confirme = window.confirm('Êtes-vous sûr de vouloir annuler cette réservation ?');
  //   if (confirme) {
  //     try{
  //       dispatch(CancelReservation(id));
  //       dispatch(FetchData(`/clientBookings/${client?.id}`, BOOKINGS));
  //     }catch(err){
  //       console.error(err);
  //     }
  //   }
  // };

  return (
    <div className="dashboard-right-contents mt-4 mt-lg-0">
      <div className="dashboard-promo">
        <div className="row gy-4 justify-content-center">
          <div className="col-xxl-3 col-xl-4 col-sm-6 orders-child">
            <div className="single-order">
              <div className="single-order-flex">
                <div className="single-order-contents">
                  <span className="single-order-contents-subtitle">
                    Réservation en attente
                  </span>
                  <h2 className="single-order-contents-title">
                    {statistics?.attente}
                  </h2>
                </div>
                <div className="single-order-icon">
                  <i className="las la-history"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 orders-child">
            <div className="single-order">
              <div className="single-order-flex">
                <div className="single-order-contents">
                  <span className="single-order-contents-subtitle">
                    Réservation acceptée
                  </span>
                  <h2 className="single-order-contents-title">
                    {statistics?.accepte}
                  </h2>
                </div>
                <div className="single-order-icon">
                  <i className="las la-check-circle"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 orders-child">
            <div className="single-order">
              <div className="single-order-flex">
                <div className="single-order-contents">
                  <span className="single-order-contents-subtitle">
                    Réservation annulée
                  </span>
                  <h2 className="single-order-contents-title">
                    {statistics?.annulee}
                  </h2>
                </div>
                <div className="single-order-icon">
                  <i className="las la-times-circle"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-6 orders-child">
            <div className="single-order">
              <div className="single-order-contents">
                <span className="single-order-contents-subtitle">
                  Réservation terminée
                </span>
                <h2 className="single-order-contents-title">
                  {statistics?.termine}
                </h2>
              </div>
              <div className="single-order-icon">
                <i className="las la-clipboard-check"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-reservation">
        {currentItems?.map((item, index) => {
          const status =
            item?.availability?.date &&
            GetBookingStatus(
              item?.availability?.date,
              item?.availability?.start_time,
              item?.availability?.end_time,
              item?.status
            );

          return (
            <div
              className={`single-reservation bg-white base-padding ${
                expandedItems === index ? "show" : ""
              }`}
              key={index}
            >
              <div
                className="single-reservation-expandIcon"
                onClick={() => toggleItem(index)}
              >
                {expandedItems === index ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
              <div className="single-reservation-head">
                <div className="single-reservation-flex">
                  <div className="single-reservation-content">
                    <h5 className="single-reservation-content-title">
                      ID de réservation
                    </h5>
                    <span className="single-reservation-content-id">
                      # {item?.id}
                    </span>
                  </div>
                  <div className="single-reservation-btn">
                    <button className={`dash-btn btn-${status?.style}`}>
                      {status?.type}
                    </button>
                  </div>
                </div>
              </div>
              <div className="single-reservation-inner">
                <div className="single-reservation-item">
                  <div className="single-reservation-name">
                    <h5 className="single-reservation-name-title">
                      {item?.availability?.salon?.name}
                    </h5>
                    <p className="single-reservation-name-para">
                      {item?.availability?.salon?.address},{" "}
                      {item?.availability?.salon?.city}
                    </p>
                  </div>
                </div>
                <div className="single-reservation-item">
                  <div className="single-reservation-details">
                    <div className="single-reservation-details-item">
                      <span className="single-reservation-details-subtitle">
                        Date de réservation
                      </span>
                      <h5 className="single-reservation-details-title">
                        {item?.availability?.date &&
                          FormateDateRange(
                            item?.availability?.date,
                            item?.availability?.start_time,
                            item?.availability?.end_time
                          )}
                      </h5>
                    </div>
                    <div className="single-reservation-details-item">
                      <span className="single-reservation-details-subtitle">
                        Services
                      </span>
                      <h5 className="single-reservation-details-title">
                        {item?.services
                          ?.map((service) => service?.name)
                          ?.join(", ")}
                      </h5>
                    </div>
                    <div className="single-reservation-details-item">
                      <span className="single-reservation-details-subtitle">
                        Réservé le
                      </span>
                      <h5 className="single-reservation-details-title">
                        {item?.created_at && FormatDate2(item?.created_at)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="single-reservation-item">
                  <div className="single-reservation-flex">
                    <div className="single-reservation-content">
                      <h5 className="single-reservation-content-title">
                        Facture totale
                      </h5>
                      <span className="single-reservation-content-price">
                        {item?.payment?.amount} Dhs
                      </span>
                    </div>
                    <div className="single-reservation-logoPrice">
                      {item?.payment?.payment_method?.toLowerCase() !==
                      "card" ? (
                        <span className="uppercase">
                          {item?.payment?.payment_method}
                        </span>
                      ) : (
                        <>
                          <span className="single-reservation-logoPrice-thumb">
                            <img src={img2} alt="img" />
                          </span>
                          <span className="single-reservation-logoPrice-code">
                            ***9320
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {(status?.type === "Terminé" && !item?.reviews?.length > 0) ? (
                  <div className="single-reservation-item">
                    <div className="flex items-center justify-between mb-2 cursor-pointer" onClick={()=>setIsReviewOpen(!isReviewOpen)}>
                      <h5 className="text-lg font-semibold">
                        Laissez votre avis
                      </h5>
                      <button className="p-2">
                        <i
                          class={`bi bi-chevron-${
                            isReviewOpen ? "up" : "down"
                          }`}
                        ></i>
                      </button>
                    </div>
                    {isReviewOpen && (
                      <div className="review-form w-full">
                        <textarea
                          className="w-full p-2 border rounded mb-2"
                          rows="3"
                          placeholder="Votre commentaire..."
                          value={review}
                          onChange={(e) => setReview(e.target.value)}
                        ></textarea>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center mb-2">
                            <span className="mr-2 font-semibold">Note :</span>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <button
                                key={star}
                                className={`text-2xl focus:outline-none ${
                                  star <= selectedRating ? "text-[#ffd700]" : ""
                                }`}
                                onClick={() => handleRatingClick(star)}
                              >
                                {star <= selectedRating ? "★" : "☆"}
                              </button>
                            ))}
                          </div>
                          <button
                            onClick={() => handleReviewSubmit(item)}
                            className="bg-orange-400 text-white px-3 py-1 rounded hover:bg-orange-500 transition-colors"
                          >
                            Soumettre l'avis
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ):
                <div className="single-reservation-item">
                    <div className="flex items-center justify-between mb-2 cursor-pointer" onClick={()=>setIsReviewOpen(!isReviewOpen)}>
                      <h5 className="text-lg font-semibold">
                        Avis ajouté !
                      </h5>
                      <button className="p-2">
                        <i
                          class={`bi bi-chevron-${
                            isReviewOpen ? "up" : "down"
                          }`}
                        ></i>
                      </button>
                    </div>
                    {isReviewOpen && (
                      <div className="review-form w-full">
                        <textarea
                          className="w-full p-2 border rounded mb-2"
                          rows="3"
                          value={item?.reviews[0]?.comment}
                        ></textarea>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center mb-2">
                            <span className="mr-2 font-semibold">Note :</span>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <button
                                key={star}
                                className={`text-2xl focus:outline-none ${
                                  star <= item?.reviews[0]?.rating ? "text-[#ffd700]" : ""
                                }`}
                              >
                                {star <= item?.reviews[0]?.rating ? "★" : "☆"}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>}
                {/* {item?.availability?.date &&
                  GetBookingStatus(
                    item?.availability?.date,
                    item?.availability?.start_time,
                    item?.availability?.end_time,
                    item?.status
                  )?.type === "En Attente" && (
                    <div className="single-reservation-item">
                      <div className="single-reservation-flex">
                        <div className="single-reservation-name">
                          <h5 className="single-reservation-name-title">
                                  {item?.availability?.salon?.name}
                                </h5>
                                <p className="single-reservation-name-para">
                                  {item?.address}
                                </p>
                        </div>
                        <div className="single-reservation-btn">
                          {item?.availability?.date &&
                            GetBookingStatus(
                              item?.availability?.date,
                              item?.availability?.start_time,
                              item?.availability?.end_time,
                              item?.status
                            )?.type === "En Attente" && (
                              <button className="dash-btn popup-click" onClick={()=>handleCancellReservation(item?.id)}>
                                <CancelIcon /> Annuler
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          );
        })}
      </div>

      {bookings?.length > itemsPerPage && (
        <div className="row mt-5">
          <div className="col">
            <div className="pagination-wrapper">
              <ul className="pagination-list list-style-none">
                <li className="pagination-list-item-prev">
                  <button
                    className="pagination-list-item-button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Préc
                  </button>
                </li>
                {visiblePages.map((page) => (
                  <li
                    key={page}
                    className={`pagination-list-item ${
                      currentPage === page ? "active" : ""
                    }`}
                  >
                    <button
                      className="pagination-list-item-link"
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  </li>
                ))}
                <li className="pagination-list-item-next">
                  <button
                    className="pagination-list-item-button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Suiv
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
}

export default DashMain;
