import { BannerTwo, DownloadApp, Faqs, Suggestions, TopSalon, Whattheysay, Whyus } from '../../components'
import { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux'

function HomeMobile() {
  const accueil = useSelector(state => state?.data?.accueil);
  return (
    <div className='second-page'>
      <BannerTwo />
      <TopSalon frommobile={true} title={accueil?.titleSalons} topSalons={accueil?.topSalons} />
      <Suggestions title={accueil?.titleCategories} />
      <Toaster/>
      <div className="h10"></div>
    </div>
  )
}

export default HomeMobile