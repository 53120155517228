import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Mail,
  Lock,
  User,
  Phone,
  Eye,
  EyeOff,
  CheckCircle,
  AlertCircle,
} from "lucide-react";
import { registerClient } from "../../actions/auth";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmePassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData?.password !== formData?.confirmePassword) {
      toast.error("Les mots de passe ne correspondent pas !", {
        icon: <AlertCircle className="text-red-500" size={16} />,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    dispatch(registerClient(formData)).then((result) => {
      if (result.success) {
        toast.success("Inscription terminée avec succès !", {
          icon: <CheckCircle className="text-green-500" size={16} />,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        navigate("/connexion");
      } else {
        toast.error("Échec de l'inscription : " + result.message, {
          icon: <AlertCircle className="text-red-500" size={16} />,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  };

  return (
    <div className="min-h-full bg-gray-100 flex items-center justify-center px-4 py-8">
      <div className="absolute inset-0 bg-[#fd8000] opacity-10 -z-10"></div>

      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-xl shadow-2xl w-full sm:w-96 md:w-[450px] lg:w-[600px] xl:w-[700px] max-w-full"
      >
        <h2 className="text-xl font-bold mb-4 text-center text-[#fd8000]">
          Inscrivez-vous
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3">
          <div>
            <label
              htmlFor="first_name"
              className="block text-gray-700 mb-1 flex items-center text-sm"
            >
              <User className="mr-2 text-[#fd8000]" size={16} />
              Prénom
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="Votre prénom"
              className="w-full p-3 pl-8 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="last_name"
              className="block text-gray-700 mb-1 flex items-center text-sm"
            >
              <User className="mr-2 text-[#fd8000]" size={16} />
              Nom
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Votre nom"
              className="w-full p-3 pl-8 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3">
          <div>
            <label
              htmlFor="email"
              className="block text-gray-700 mb-1 flex items-center text-sm"
            >
              <Mail className="mr-2 text-[#fd8000]" size={16} />
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Votre email"
              className="w-full p-3 pl-8 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-gray-700 mb-1 flex items-center text-sm"
            >
              <Phone className="mr-2 text-[#fd8000]" size={16} />
              Téléphone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Votre téléphone"
              className="w-full p-3 pl-8 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="mb-3 relative">
          <label
            htmlFor="password"
            className="block text-gray-700 mb-1 flex items-center text-sm"
          >
            <Lock className="mr-2 text-[#fd8000]" size={16} />
            Mot de passe
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Votre mot de passe"
              className="w-full p-3 pl-8 pr-10 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeOff className="text-gray-400" size={16} />
              ) : (
                <Eye className="text-gray-400" size={16} />
              )}
            </button>
          </div>
        </div>

        <div className="mb-3 relative">
          <label
            htmlFor="confirmePassword"
            className="block text-gray-700 mb-1 flex items-center text-sm"
          >
            <Lock className="mr-2 text-[#fd8000]" size={16} />
            Confirmer mot de passe
          </label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmePassword"
              name="confirmePassword"
              placeholder="Confirmez votre mot de passe"
              className="w-full p-3 pl-8 pr-10 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fd8000]"
              value={formData.confirmePassword}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <EyeOff className="text-gray-400" size={16} />
              ) : (
                <Eye className="text-gray-400" size={16} />
              )}
            </button>
          </div>
        </div>

        <div className="w-full flex justify-center ">
          <button
            type="submit"
            className="w-[100px] bg-[#fd8000] text-white py-3 rounded-lg text-sm hover:bg-orange-600 transition duration-300"
          >
            S'inscrire
          </button>
        </div>
      </form>

      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          success: {
            style: {
              background: "white",
              color: "black",
            },
          },
          error: {
            style: {
              background: "white",
              color: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default Register;
