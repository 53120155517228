import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/new-logo-color.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";

function Headertwo() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBtnsOpen, setIsBtnsOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update window width on resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener on unmount
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 991) {
      setIsMenuOpen(false); // Close menu if window width exceeds 991px
    }
  }, [windowWidth]);

  return (
    <nav className="navbar navbar-area navbar-border navbar-expand-lg">
      <div className="container custom-container-one nav-container">
        {/* Logo Section */}
        <div className="logo-wrapper">
          <div className="logo cursor-pointer" onClick={() => navigate("/")}>
            <img src={logo} alt="Logo" />
          </div>
        </div>

        {/* Mobile Menu and Toggler */}
        <div className="responsive-mobile-menu d-lg-none">
          <button
            className="navbar-toggler"
            aria-expanded={isMenuOpen}
            aria-controls="navbar-menu"
            onClick={handleMenuToggle}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>

        {windowWidth > 991 ? (
          <div className={`navbar-collapse show w-full`} id="navbar-menu">
            <ul className="navbar-nav">
              <li
                className={location.pathname === "/" ? "current-menu-item" : ""}
              >
                <Link to="/">{t("Accueil")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/info-apropos"
                    ? "current-menu-item"
                    : ""
                }
              >
                <Link to="/info-apropos">{t("À propos")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/sidelisting-page"
                    ? "current-menu-item"
                    : ""
                }
              >
                <Link to="/sidelisting-page">{t("Liste des salons")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/blog" ? "current-menu-item" : ""
                }
              >
                <Link to="/blog">{t("Blog")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/entreprise" ? "current-menu-item" : ""
                }
              >
                <Link to="/entreprise">{t("Pour votre entreprise")}</Link>
              </li>
            </ul>
          </div>
        ) : (
          <div
            className={`navbar-collapse mobile-one ${
              isMenuOpen ? "show" : "hidden"
            }`}
            id="navbar-menu"
          >
            <ul className="navbar-nav">
              <li
                className={location.pathname === "/" ? "current-menu-item" : ""}
              >
                <Link to="/">{t("Accueil")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/info-apropos"
                    ? "current-menu-item"
                    : ""
                }
              >
                <Link to="/info-apropos">{t("À propos")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/sidelisting-page"
                    ? "current-menu-item"
                    : ""
                }
              >
                <Link to="/sidelisting-page">{t("Liste des salons")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/blog" ? "current-menu-item" : ""
                }
              >
                <Link to="/blog">{t("Blog")}</Link>
              </li>
              <li
                className={
                  location.pathname === "/entreprise" ? "current-menu-item" : ""
                }
              >
                <Link to="/entreprise">{t("Pour votre entreprise")}</Link>
              </li>
            </ul>
            {auth?.client ? (
              <div className="auth-buttons authtwo">
                <span
                  className="register-btn"
                  onClick={() =>
                    navigate("/dashboard", { state: { selone: "dash" } })
                  }
                >
                  Mon profile
                </span>
              </div>
            ) : (
              <div className="auth-buttons authtwo">
                <Link to="/connexion" className="login-btn">
                  {t("Connexion")}
                </Link>
                <Link to="/register" className="register-btn">
                  {t("S'inscrire")}
                </Link>
              </div>
            )}
          </div>
        )}

        {/* Navbar Right Content */}
        <div className={`navbar-right-content ${isBtnsOpen ? "show" : ""}`}>
          <div className="single-right-content">
            {screenWidth > 991 && auth?.client ? (
              <div
                className="user-profile"
                onClick={() =>
                  navigate("/dashboard", { state: { selone: "dash" } })
                }
              >
                <img
                  src={`${BASE_URL_IMAGE}${auth?.client?.avatar}`}
                  alt="Profile"
                  className="profile-img"
                  onError={(e) => {
                    e.target.onerror = null; // prevents looping
                    e.target.src = ProfileImg; // set the fallback image
                  }}
                />

                <div className="username">
                  <span>{auth?.client?.first_name}</span>
                  <span>{auth?.client?.last_name}</span>
                </div>
              </div>
            ) : (
              <div className="auth-buttons authone">
                <Link to="/connexion" className="login-btn">
                  {t("Connexion")}
                </Link>
                <Link to="/register" className="register-btn">
                  {t("S'inscrire")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Headertwo;
