import React, { useEffect, useState } from "react";
import "./style.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DatePickerCust } from "../../components";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ADD_AVAILABILITY, ADD_SERVICES } from "../../constants/actions";
import { toast, Toaster } from "react-hot-toast";
import AddMinutesToTime from "../../helpers/AddMinutesToTime";
import { GetEmplAvailabilities } from "../../actions/data";
import FormatDuration from "../../helpers/FormatDuration";
import { Clock, User, X } from "lucide-react";

function AfterPannier() {
  const reduxData = useSelector((state) => state);
  const [selectedEmpl, setSelectedEmpl] = useState("");
  const [emplAvailabilities, setEmplAvailabilities] = useState([]);
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);
  const [dateTimeRange, setDateTimeRange] = useState(null);

  useEffect(() => {
    if (
      !reduxData?.reservation?.salon ||
      !reduxData?.reservation?.services?.length
    ) {
      navigate("/detail-page", { state: { id: id } });
    }
  }, []);

  useEffect(() => {
    if (reduxData?.reservation?.employee_id) {
      const defaultEmp = reduxData?.reservation?.salon?.employees?.find(
        (emp) => emp?.id === reduxData?.reservation?.employee_id
      );
      setSelectedEmpl(defaultEmp?.id || "");
    } else {
      const defaultEmp = reduxData?.reservation?.salon?.employees[0];
      setSelectedEmpl(defaultEmp?.id || "");
    }
  }, [reduxData]);

  const handleDeleteService = (service) => {
    const serviceExists = reduxData?.reservation?.services?.includes(service);

    let updatedServices;
    if (serviceExists) {
      updatedServices = reduxData?.reservation?.services?.filter(
        (ser) => ser?.id !== service?.id
      );
    } else {
      updatedServices = [...(reduxData?.reservation?.services || []), service];
    }
    dispatch({ type: ADD_SERVICES, payload: updatedServices });
  };

  const renderSelectedValue = (value) => {
    if (value === null) {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <PersonOutlineIcon />
          <span>{"Un(e) employé(e)"}</span>
        </Box>
      );
    } else {
      const employe = reduxData?.reservation?.salon?.employees?.find(
        (emp) => emp?.id === parseInt(value)
      );
      return (
        <Box display="flex" alignItems="center">
          <span>
            {employe?.first_name} {employe?.last_name}
          </span>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (reduxData?.reservation?.services?.length) {
      const total = reduxData?.reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );
      setTotalPrice(total.toFixed(2));
    }
  }, [reduxData?.reservation]);

  useEffect(() => {
    if (selectedEmpl) {
      const getFiltredAvailabilities = async (id) => {
        const result = await dispatch(GetEmplAvailabilities(id)).then(
          (response) => response
        );
        setEmplAvailabilities(result);
      };
      getFiltredAvailabilities(selectedEmpl);
    }
  }, [selectedEmpl]);

  const handleVerify = () => {
    if (dateTimeRange) {
      const totalMinutes = reduxData?.reservation?.services?.reduce(
        (sum, service) => {
          const durationStr = service?.pivot?.duration || "0000";
          const hours = parseInt(durationStr?.slice(0, 2), 10) || 0;
          const minutes = parseInt(durationStr?.slice(2, 4), 10) || 0;
          return sum + hours * 60 + minutes;
        },
        0
      );

      const availability = {
        date: dateTimeRange?.date,
        start_time: dateTimeRange?.start_time,
        end_time: AddMinutesToTime(dateTimeRange?.start_time, totalMinutes),
        employee_id: selectedEmpl,
      };

      dispatch({
        type: ADD_AVAILABILITY,
        payload: availability,
      });

      navigate("/checkout");
    } else {
      toast.error(
        "Il faut sélectionner l'heure de début de votre rendez-vous"
      );
    }
  };

  return (
    <div className="after-pannier shadow-lg rounded-lg">
      {reduxData?.reservation?.services?.length ? (
        <div className="all-pannier row">
          <div className="col-md-6 col-lg-5">
            <div className="select-empl">
            <h3 className="text-lg font-semibold flex items-center">
                <User className="mr-2 text-orange-500" />
                <span className="text-black">Sélectionner un employé</span>
              </h3>
              <div className="one-empl">
                <FormControl sx={{ m: 1, width: "90%" }}>
                  <Select
                    value={selectedEmpl || ""}
                    onChange={(e) => setSelectedEmpl(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={renderSelectedValue}
                    sx={{
                      fontFamily: "CustomFont, sans-serif",
                      backgroundColor: "#fff",
                    }}
                  >
                    {reduxData?.reservation?.salon?.employees?.map((emp) => (
                      <MenuItem
                        sx={{ fontFamily: "CustomFont, sans-serif" }}
                        value={emp?.id}
                        key={emp?.id}
                      >
                        {emp?.first_name} {emp?.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="select-date">
              <span>Sélectionnez l'heure de début de votre rendez-vous</span>
              <DatePickerCust
                setDateTimeRange={setDateTimeRange}
                reservation={reduxData?.reservation}
                emplAvailabilities={emplAvailabilities}
                selectedEmpl={selectedEmpl}
                salon={reduxData?.reservation?.salon}
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-7">
            <span className="pannier-title">Mon Panier</span>
            <div className="all-services-selected space-y-4 max-h-96 overflow-y-auto">
              {reduxData?.reservation?.services?.map((service, index) => (
                // <div key={index} className="selected-service">
                //   <div className="service-time">
                //     <span>{service.name}</span>
                //     <span>{service?.pivot?.price} Dhs</span>
                //     <span>{FormatDuration(service?.pivot?.duration)}</span>
                //   </div>
                //   <HighlightOffIcon
                //     className="cursor-pointer"
                //     onClick={() => handleDeleteService(service)}
                //   />
                // </div>
                <div 
                  key={service.id} 
                  className="flex justify-between items-center p-3 bg-orange-100 rounded-md"
                >
                  <div className="flex-grow">
                    <div className="font-semibold">{service.name}</div>
                    <div className="text-sm text-gray-600 flex items-center">
                      <Clock className="mr-2 text-orange-500" size={16} />
                      {FormatDuration(service?.pivot?.duration)} - {" "}
                      {service?.pivot?.price} Dhs
                    </div>
                  </div>
                  <button 
                    onClick={() => handleDeleteService(service)}
                    className="text-red-500 hover:text-red-700 ml-4"
                  >
                    <X />
                  </button>
                </div>
              ))}
            </div>
            <div className="add-more-services mt-2">
              <AddCircleOutlineIcon />
              <span
                onClick={() => navigate("/detail-page", { state: { id: id } })}
              >
                Ajouter d'autre service
              </span>
            </div>
            <div className="mt-6 p-4 rounded-md">
              <div className="flex justify-between mb-4">
                <span className="font-bold text-black">Total de la commande</span>
                <span className="text-xl font-bold text-black">
                  {totalPrice} Dhs
                </span>
              </div>
              <button 
                onClick={handleVerify}
                className="w-full p-3 bg-orange-500 text-white rounded-md hover:bg-orange-600"
              >
                Vérifier
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="all-pannier-free-container">
          <div className="all-pannier-free">
            <span>Votre panier est vide</span>
            <div
              onClick={() => navigate("/detail-page", { state: { id: id } })}
              className="add-prestation"
            >
              <AddCircleOutlineIcon />
              <span>Ajouter une ou plusieurs prestations</span>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
}

export default AfterPannier;
