import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Home, User, Calendar, Store } from "lucide-react";

const AppFooter = () => {
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();

  // icon: (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     stroke="currentColor"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <path d="M12 20l9.5-9.5a4.24 4.24 0 0 0-6-6L12 8" />
  //     <line x1="12" y1="12" x2="4.2" y2="19.8" />
  //   </svg>
  // ),

  const footerTabs = [
    {
      id: "home",
      link: "/",
      icon: <Home size={24} />,
      label: "Explorer",
    },
    {
      id: "salons",
      link: "/sidelisting-page",
      icon: <Store size={24} />,
      label: "Salons",
    },
    {
      id: "reservations",
      link: "/my-reservations",
      icon: <Calendar size={24} />,
      label: "Réservations",
    },
    {
      id: "profile",
      link: "/profil",
      icon: <User size={24} />,
      label: "Profil",
    },
  ];

  // Update the active tab based on the current location
  useEffect(() => {
    const currentTab = footerTabs.find((tab) => tab.link === location.pathname);
    if (currentTab) {
      setActiveTab(currentTab.id);
    }
  }, [location.pathname]);

  return (
    <footer className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-lg border-t border-gray-100">
      <div className="grid grid-cols-4">
        {footerTabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => navigate(tab.link)}
            className={`flex flex-col items-center justify-center p-3 transition-colors duration-200 ${
              activeTab === tab.id
                ? "text-[#fd8000] bg-orange-50"
                : "text-gray-500 hover:text-[#fd8000]"
            }`}
          >
            {tab.icon}
            <span className="text-xs mt-1">{tab.label}</span>
          </button>
        ))}
      </div>
    </footer>
  );
};

export default AppFooter;