import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";

// Import icons
import iconb1 from "../../assets/img/icons/b1.svg";
import iconb2 from "../../assets/img/icons/b2.svg";
import iconb3 from "../../assets/img/icons/b3.svg";
import iconb4 from "../../assets/img/icons/b4.svg";

function Whyus({ title }) {
  const bookingData = useSelector(state => state?.data?.reasons);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const iconMapping = {
    iconb1,
    iconb2,
    iconb3,
    iconb4
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="booking-area pat-100 pab-50">
      <div className="container">
        <div className="section-title center-text">
          <h2 className="title"> {title} </h2>
          <div className="section-title-line"> </div>
        </div>
        {screenWidth <= 768 ? (
          <div className="row gy-4 mt-5">
            <Carousel>
              {bookingData?.map((item, index) => (
                <Carousel.Item key={index}>
                  <div
                    className={`col-xl-3 col-lg-4 col-md-6 wow fadeIn${
                      index % 2 === 0 ? "Right" : "Left"
                    }`}
                    style={{ visibility: "visible" }}
                  >
                    <div className="single-why center-text bg-white single-why-border radius-10 h-full">
                      <div className="single-why-icon">
                        <img src={iconMapping[item?.icon]} alt="icon" />
                      </div>
                      <div className="single-why-contents mt-3">
                        <h4 className="single-why-contents-title">
                          <Link to="#"> {item?.title} </Link>
                        </h4>
                        <p className="single-why-contents-para mt-3" dangerouslySetInnerHTML={{ __html: item?.description }}/>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="row gy-4 mt-5">
            {bookingData?.map((item, index) => (
              <div
                key={index}
                className={`col-xl-3 col-lg-4 col-md-6 wow fadeIn${
                  index % 2 === 0 ? "Right" : "Left"
                }`}
                style={{ visibility: "visible" }}
              >
                <div className="single-why center-text bg-white single-why-border radius-10 h-full">
                  <div className="single-why-icon">
                    <img src={iconMapping[item?.icon]} alt="icon" />
                  </div>
                  <div className="single-why-contents mt-3">
                    <h4 className="single-why-contents-title">
                      <button> {item?.title} </button>
                    </h4>
                    <p className="single-why-contents-para mt-3" dangerouslySetInnerHTML={{ __html: item?.description }}/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Whyus;